*,
::after,
::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    font-weight: 400;
    background: rgb(255, 255, 255);
    color: rgb(34, 42, 48);

    @media (prefers-color-scheme: dark) {
        background: rgb(45, 53, 58);
        color: rgb(235, 235, 235);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    margin: 0;
}

h1 {
    font-weight: 700;
    font-size: 42px;
}

a {
    text-decoration: none;
    color: rgb(34, 42, 48);

    @media (prefers-color-scheme: dark) {
        color: rgb(235, 235, 235)
    }
}

img {
    max-width: 100%;
}

textarea {
    font-family: proxima-nova, sans-serif;
}

.ContactForm__Body--open {
    height: 100vh;
    overflow-y: hidden;
}

select {
    color: #5b5a5a;
    border-radius: 10px;
    border: 2px solid #dadada;
    min-width: 50px;
    padding-left: 5px;
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(https://neo.completelyretail.co.uk/icons/simplearrow-down-grey.svg) 87%/20% no-repeat;
}

select:focus {
    border-radius: 10px;
    outline: 0;
    box-shadow: 0 0 2px #bababa;
}

.ReactModal__Overlay {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullscreen {
    min-height: calc(100vh - 70px);
}

h1 {
    font-weight: 700;
    font-size: 42px;
}

 
.modal .modal-content {
    border-radius: 15px;
    padding: 2em;
}

.scrollable {
    max-height: 400px;
    overflow-y: scroll;
    position: relative;
}

.gm-style-iw-d {
    margin-bottom: 0 !important;
}

.gm-style-iw-d div {
    margin-bottom: 0 !important;
}

.scrollList::-webkit-scrollbar {
    display: none;
}

.scrollList {
    -ms-overflow-style: none;
}

.grecaptcha-badge {
    z-index: 2000;
}

body>div>div.grecaptcha-badge {
    display: none !important;

}


.accordion__item {
    margin-bottom: 10px;
}

.accordion__button:focus,
.accordion__button:active {
    outline: none;
}

.accordion__button::before {
    display: inline-block;
    content: "";
    height: 10px;
    width: 10px;
    margin-bottom: 3px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transition: transform 0.2s ease;
    transform: rotate(-45deg);
}

.accordion__button {
    font-size: 1em;
    color: #444;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    margin: 12px 0;
    padding: 3px 0px;

    @media (prefers-color-scheme: dark) {
        color: rgb(235, 235, 235);
    }
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
    transform: rotate(45deg);
}

.portfolio {
    .swiper-wrapper {
        transition-timing-function: linear;
    }

    .swiper-button-prev,
    .swiper-button-next {
        color: #fff;
    }

    .swiper-pagination-bullet {
        background: #fff;
        opacity: 0.35;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
    }

    .swiper-slide {
        position: relative;
    }

    .swiper-slide:before {
        content: "";
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
        padding-bottom: 100%;
    }

    .swiper-slide a {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        display: block;
    }


    .swiper-slide a:after {
        content: "";
        position: relative;
        display: block;
        z-index: 3;
        width: 100%;
        padding-bottom: 100%;
    }

    .swiper-slide a svg {
        position: absolute;
        width: 100%;
        top: 50%;
        z-index: 4;
        left: 25px;
        filter: brightness(0) opacity(0.4);
        transition: filter 200ms;
        transform: translateY(-50%);

        @media (prefers-color-scheme: dark) {
            filter: brightness(1) grayscale(1) invert(1) opacity(1);
        }
    }

    .swiper-slide a :hover svg {
        filter: brightness(1) opacity(1);
    }

    .swiper-pagination-bullet-active {
        height: 1rem !important;
    }
}


.visiableCookie {
    transform: translatey(-13rem);
    transition: transform .5s ease-in-out;

}

.displayNoneCookie {
    display: none !important;
}


@media screen and (max-width: 768px) {

    .MuiTablePagination-selectLabel,
    .MuiTablePagination-spacer {
        display: none;
    }
}

@media screen and (max-width: 300px) {
    .MuiInputBase-root {

        margin-right: 20px;
        margin-left: 0px;
    }
}


body.Modal__Body--open {
    overflow: hidden !important;

}

@media screen and (min-width: 768px) {
    .Modal__Overlay.ReactModal__Overlay--after-open {
        top: 0;
    }}

.Modal__Overlay.ReactModal__Overlay--after-open {
    position: fixed;
    z-index: 1500;
    inset: 0px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}

.Modal__Content {

    position: relative;
    padding: 1em;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 15px;
    text-align: center;
    max-height: 90vh;
    overflow: scroll;
    background-color: white;
    outline: none;

    ::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width:none;
    -ms-overflow-style:none;

    @media (prefers-color-scheme: dark) {
        background: rgb(29, 37, 44);
        color: rgb(235, 235, 235);
    }

    @media (min-width: 576px) {
        padding: 2em;

    }
}
